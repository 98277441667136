@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz,wght@6..96,500&display=swap");

@font-face {
    font-family: basic;
    src: url("../../assets/fonts/basicsanssf.ttf");
}

* {
    margin: 0;
    padding: 0;
    color: #3f3f3f;
}

img {
    width: 100%;
}



a {
    text-decoration: none;
}

a:hover {
    color: #000;
    /* cursor: url("../../assets/img/cursor-icon-hover.png"), auto; */
}

.container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    width: 100%;
    /* border: 1px solid #f0f; */
    align-self: center;
    /* padding: 3rem 0; */
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo img {
    width: 60%;
    margin-bottom: 2rem;
}
.desc{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.desc p {
    text-align: center;
    margin-bottom: 1.5rem;
}

.desc p:nth-child(1) {
    font-family: "Bodoni Moda";
    font-weight: 500;
    font-size: 2.25rem;
}

.desc p:nth-child(2) {
    font-size: 3.5rem;
    font-family: basic;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5rem;
}
.menu-btn{
    font-size: 1.5rem !important;
    padding: 1rem 2rem;
    border: #3f3f3f 1px solid;
    width: fit-content;
    transition: .2s ease;
    margin: 1.5rem 0 0 0;
}

.menu-btn:hover{
    color: #fff;
    background-color: #3f3f3f;
    cursor: pointer;
}

.menu-btn:hover a{
    color: #fff;
}

.location {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icons {
    width: 80%;
    margin-bottom: 1rem;
}

.icons ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
}

.icons ul img {
    width: 2rem;
    transition: 0.3s ease;
}

.icons ul img:hover {
    transform: scale(1.2);
}

.location p {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-family: "Bodoni Moda";
}

.location p img {
    margin-left: 1rem;
    width: 2rem;
    transition: 0.3s ease;
}

.location p a:hover img {
    transform: scale(1.2) translateX(0.3rem);
}

@media screen and (min-width: 1890px) {
    .container {
        height: 80%;
    }

    .location p {
        font-size: 2.2rem;
    }

    .icons ul img {
        width: 2.5rem;
    }

    .icons {
        width: 60%;
    }

    .logo img {
        width: 70%;
    }

    .desc p:nth-child(1) {
        font-size: 2.25rem;
    }

    .desc p:nth-child(2) {
        font-size: 4rem;
    }

    .container {
        height: 80%;
    }
}

@media screen and (max-width: 768px) {
    .location p {
        font-size: 1.2rem;
    }

    .icons ul img {
        width: 1.5rem;
    }

    .icons {
        width: 60%;
    }

    .desc p:nth-child(1) {
        font-size: 1.25rem;
    }

    .desc p:nth-child(2) {
        font-size: 2rem;
    }

    .container {
        height: 80vh;
        width: fit-content;
        padding: 0 .5rem;
    }
}